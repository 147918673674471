// CallNum.js

import React from "react";
import "../Styles/HeaderStyle.css";

function CallNum() {
  const phoneNumber = '(480)757-5148';
  
  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div className="callNumBubble">
      <span className="callNumText">{phoneNumber}</span>
    </div>
  );
}

export default CallNum;