import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import Image1 from "../WebImg/ComImg/Business_pic.jpg";
import Image2 from "../WebImg/ComImg/wires.jpg";
import Image3 from "../WebImg/ComImg/IMG_3761.jpg";
import Image4 from "../WebImg/ComImg/IMG_3755.jpg";
import { IoIosArrowForward } from "react-icons/io";
import "../Styles/Com_Style.css";
import "../index.css";

function Commercial() {
  useEffect(() => { window.scrollTo(0, 0); }, []);

  return (
    <>
      {/* Header */}
      <header className="headerCS">
        <div className="headerTitleCS">
          <div>
            <h1 className="headingStyleCS">Commercial Monitoring and Security</h1>
          </div>
        </div>
        <div className="IMGcontainerCS">
          <div className="IMGcardHeaderC" style={{backgroundImage: `url(${Image1})`, height: 300, width: '100%', marginLeft: 0}}> </div>
        </div>
       
      </header>

      {/* Introduction */}
      <section>
        <div className="TotalContC">
          <main className="TextContainerC">
            <div className="infoBoxC">
              <h2>Empowering Your Commercial Security</h2>
              <p>
                Enhance the safety and protection of your business with our comprehensive security solutions. Our advanced electronic systems are designed to meet the complex needs of commercial environments.
                <br/>
                <ul style={{listStyle: 'none',}}>
                  <li>Structured Wiring</li>
                  <li>Live Monitoring</li>
                  <li>Alarm Systems</li>
                  <li>Audio / Video</li>
                </ul> 

                <Link to='/Contact' className="Fbtn">
                    Financing available, Call us to learn more!
                    <IoIosArrowForward style={{color: 'white'}} size={35} />
                </Link>
              </p>
            </div>

            <div className="infoBoxC">
              <h2>Surveillance</h2>
              <p>
                Monitor and secure your premises with advanced <strong>surveillance systems</strong> tailored to your business needs. Our CCTV surveillance solutions provide high-definition video and reliable performance to keep your business safe.
                <br/>
                <br/>
                Want to know more about camera specifications? Check out the cameras we use by visiting <a style={{textDecoration: 'none', color: 'blue'}} href="https://www.cctvcore.com" target="_blank" rel="noopener noreferrer">www.cctvcore.com</a>.
              </p>
            </div>

            <div className="infoBoxC">
              <h2>Alarm Systems</h2>
              <p>
                Stay vigilant and respond swiftly to any security breaches with our state-of-the-art alarm systems. Our advanced security solutions are designed to protect your business around the clock.
                <br/>
                <br/>
                <strong>Ask about our 24/7 Monitoring!</strong>
              </p>
            </div>

            <div className="infoBoxC">
              <h2>Access Control</h2>
              <p>
                Regulate entry and restrict unauthorized access to sensitive areas of your business premises with our access control solutions. Ensure your business's security with cutting-edge access management technologies.
              </p>
            </div>

            <div className="infoBoxC">
              <h2>Network Security Solutions</h2>
              <p>
                Protect your digital assets with our comprehensive network security solutions. We provide secure and efficient networking for businesses, ensuring your data remains protected against cyber threats.
              </p>
            </div>
          </main>

          <aside className="IMGcontainerC">
            <div>
             
                <div className="IMGcardVC" style={{ backgroundImage: `url(${Image2})` }} alt="Commercial structured wiring setup"></div>
          
                <div className="IMGcardVCM" style={{ backgroundImage: `url(${Image3})` }} alt="Commercial network security setup"></div>
            
            </div>
            <div>
          
                <div className="IMGcardVC" style={{ backgroundImage: `url(${Image4})` }} alt="Business surveillance camera installation"></div>
       
            </div>
          </aside>
        </div>
      </section>

      {/* Contact Link */}
      <div>
        <Link className="CButtonC" to="../Contact" style={{ top: 150 }}>
          LET'S SECURE YOUR BUSINESS.
          CONTACT US!
          <IoIosArrowForward style={{color: 'white'}} size={35} />
        </Link>
      </div>
    </>
  );
}

export default Commercial;